<template>
  <div class="mt--2">
    <KTPortlet>
      <template v-slot:title>
        <h3
          class="kt-portlet__head-title"
          style="width: 100%"
          v-if="product_info_items.length == 1"
        >
          {{ product_info_items[0].article_information.brand }}/
          {{ product_info_items[0].article_information.brand_article_number }}/
          {{ product_info_items[0].article_information.product_name }}
        </h3>
      </template>
      <template v-slot:body>
        <b-overlay :show="loading">
          <b-tabs content-class="mt-3 mb-7" lazy>
            <b-tab title="Overview" v-if="list_filter != 0">
              <!-- {{ tableSubmit }} -->
              <Advanced
                v-if="tableSubmit != null"
                :searchConfiguration.sync="searchConfiguration"
                :searchObj.sync="tableSubmit"
                :tableId.sync="tableData.tabId"
                no-footer
              ></Advanced>
              <SimpleTable
                :items="product_info_items"
                :fields="product_info_fields"
                :tableId="'product_info_detail'"
                :options="options"
                :searchObj="tableSubmit"
                style="margin-top: 20px"
              ></SimpleTable>
              <b-col md="12">
                <b-nav tabs class="form-section">
                  <b-nav-item disabled>Targets Information</b-nav-item>
                </b-nav>
                <CustomTable
                  v-if="tableSubmit != null && list_filter != 0"
                  :itemsFn="itemsFn"
                  :tableData="tableData"
                  :sortName="sort_name"
                  :tableObj="tableSubmit"
                  :tableId.sync="tableData.tabId"
                  :options="options"
                ></CustomTable>
              </b-col>
            </b-tab>
          </b-tabs>
        </b-overlay>

        <div class="console" v-if="ifConsole">
          <b-overlay :show="loading">
            <router-link
              :to="{
                name: 'purchase-summary-list'
              }"
              class="btn btn-secondary mr-2"
              >Back to List
              <b-icon icon="reply-fill" aria-hidden="true"></b-icon
            ></router-link>
          </b-overlay>
        </div>
      </template>
    </KTPortlet>
  </div>
</template>

<script>
import KTPortlet from '@/views/content/Portlet';
import apiBus from '@/common/apiBus/index';
import { mapState } from 'vuex';
import { RENEW_BASE_CONFIG } from '@/store/modules/baseconfig/actions';
import Advanced from '@/components/Search/Ordinary.vue';
import CustomTable from '@/components/Table/Index.vue';
import SimpleTable from '@/components/Table/Simple.vue';
import { PROMPT_STATUS } from '@/store/modules/statusprompt/actions';
// import SimpleTable from '@/components/Table/Simple';
export default {
  name: 'purchaseOverviewDetail',
  components: {
    KTPortlet,
    Advanced,
    CustomTable,
    SimpleTable
  },
  data() {
    return {
      product_id: null,
      loading: false,
      ifConsole: true,
      product_info_items: [],
      product_info_fields: [
        {
          key: 'image',
          label: 'Article Image'
          // sortDesc: true
        },
        {
          key: 'article_information',
          label: 'Article Information'
        },
        {
          key: 'qty',
          label: 'Total Target Quantity'
        },
        {
          key: 'not_purchased_qty',
          label: 'Total To be purchased'
        },
        {
          key: 'reserved_qty',
          label: 'Total Reserved'
        },
        {
          key: 'confirmed_qty',
          label: 'Total Confirmed'
        },
        {
          key: 'delivered_qty',
          label: 'Total Delivered'
        },
        {
          key: 'stored_qty',
          label: 'Total Stored'
        }
      ],
      searchConfiguration: {
        order_date: {
          type: 'datepicker_from_to',
          md: '6',
          text: 'Order Date',
          limit: {
            end: 0
          },
          from: {
            placeholder: 'From'
          },
          to: {
            placeholder: 'To'
          }
        },
        sales_channel: {
          type: 'select',
          text: 'Sales Channel',
          // icon: 'bar-chart-fill',
          placeholder: this.$t('page.please_choose'),
          multiple: true,
          options: []
        },
        order_type: {
          type: 'checked-all',
          text: 'Order Type',
          allSelected: true,
          class: 'text_color_dashed_all',
          // resetVal: [1, 2],
          options: [
            { label: 'Retail', value: 1 },
            { label: 'Wholesale', value: 2 },
            { label: 'Deposit', value: 3 }
          ]
        },
        priority: {
          type: 'checked-all',
          text: 'Priority',
          allSelected: true,
          class: 'text_color_dashed_all',
          resetVal: [1, 2],
          options: [
            { label: 'VIP', value: 1, class: 'text_red_dashed' },
            { label: 'Normal', value: 2, class: 'text_grey_dashed' }
            // { label: 'Urgent', value: 3, class: 'text_yellow_dashed' }
          ]
        }
      },
      tableSubmit: {
        // product_id: null,
        order_date_from: null,
        order_date_to: null,
        sales_channel: null,
        priority: [1, 2],
        order_type: [1, 2, 3],
        limit: 100,
        page: 1
      },
      list_filter: 0,
      tableData: {
        fields: [
          {
            key: 'order_information',
            label: 'Order Information'
            // sortDesc: true
          },
          {
            key: 'sales_channel',
            label: 'Sales Channel'
          },
          {
            key: 'order_type',
            label: 'Order Type'
          },
          {
            key: 'order_priority',
            label: 'Priority'
          },
          {
            key: 'to_warehouse_deadline',
            label: 'To Warehouse Deadline'
          },
          {
            key: 'qty',
            label: 'Target Quantity'
          },
          {
            key: 'not_purchased_qty',
            label: 'To be purchased'
          },
          {
            key: 'reserved_qty',
            label: 'Reserved'
          },
          {
            key: 'confirmed_qty',
            label: 'Confirmed'
          },
          {
            key: 'delivered_qty',
            label: 'Delivered'
          },
          {
            key: 'stored_qty',
            label: 'Stored'
          },
          {
            key: 'action',
            label: ''
          }
        ],
        tabId: 'purchase_summary_detail',
        rowClass: '',
        class: 'new_table',
        items: [],
        status: null,
        totalItems: 9999999999999,
        pageOptions: [10, 30, 50, 100, 200]
      },
      sort_name: '',
      options: {
        sales_channel: [],
        supplier_list: [],
        order_type: [
          { label: 'Retail', value: 1 },
          { label: 'Wholesale', value: 2 },
          { label: 'Deposit', value: 3 }
        ]
      }
    };
  },

  methods: {
    add0(m) {
      return m < 10 ? '0' + m : m;
    },
    format(shijianchuo) {
      //shijianchuo是整数，否则要parseInt转换
      var time = new Date(shijianchuo);
      var y = time.getFullYear();
      var m = time.getMonth() + 1;
      var d = time.getDate();
      return y + '-' + this.add0(m) + '-' + this.add0(d);
    },
    modelHide() {
      if (document.getElementsByClassName('form_error').length > 0) {
        var scroll_top = 0;
        if (document.documentElement && document.documentElement.scrollTop) {
          scroll_top = document.documentElement.scrollTop;
        } else if (document.body) {
          scroll_top = document.body.scrollTop;
        }
        var divTop = document
          .getElementsByClassName('form_error')[0]
          .getBoundingClientRect().top;
        console.log(divTop);
        window.scrollTo(
          0,
          divTop + scroll_top - document.documentElement.clientHeight / 3
        );
      }
    },
    outputLabel(data, val) {
      let label;
      data.forEach(item => {
        if (item.value == val) {
          label = item.label;
        }
      });
      return label;
    },
    searchTableData(data) {
      var tableData = [];
      data.forEach(key => {
        let {
          id,
          cps_order_number,
          order_date,
          cps_order_url,
          sales_channel,
          order_type,
          order_priority,
          to_warehouse_deadline,
          qty,
          not_purchased_qty,
          reserved_qty,
          confirmed_qty,
          delivered_qty,
          stored_qty
        } = key;

        tableData.push({
          id: id,
          order_information: {
            cps_order_number: cps_order_number,
            order_date: order_date,
            cps_order_url: cps_order_url
          },
          sales_channel: sales_channel,
          order_type: this.outputLabel(this.options.order_type, order_type),
          order_priority: order_priority,
          to_warehouse_deadline: to_warehouse_deadline,
          qty: qty,
          not_purchased_qty: not_purchased_qty,
          reserved_qty: reserved_qty,
          confirmed_qty: confirmed_qty,
          delivered_qty: delivered_qty,
          stored_qty: stored_qty,
          action: 'PurchaseSummaryDetail'
        });
      });
      return tableData;
    },
    productInfoData(data) {
      var tableData = [];
      tableData.push({
        image: data.product_info[0].image_url,
        article_information: {
          brand: data.product_info[0].brand,
          brand_article_number: data.product_info[0].brand_article_number,
          product_name:
            data.product_info[0].product_name_en != '' &&
            data.product_info[0].product_name_en != null
              ? data.product_info[0].product_name_en
              : data.product_info[0].product_name,
          color: data.product_info[0].color,
          color_name: data.product_info[0].color_name,
          size: data.product_info[0].size,
          pos_article_no: data.product_info[0].pos_article_no
        },
        qty: data.product_info[0].qty,
        not_purchased_qty: data.product_info[0].not_purchased_qty,
        reserved_qty: data.product_info[0].reserved_qty,
        confirmed_qty: data.product_info[0].confirmed_qty,
        delivered_qty: data.product_info[0].delivered_qty,
        stored_qty: data.product_info[0].stored_qty,
        product_id: data.product_info[0].product_id,
        action: 'PurchaseSummaryDetail'
      });
      return tableData;
    },
    itemsFn() {
      this.loading = true;
      this.save_data = [];
      console.log(this.tableSubmit);

      var data = this.tableSubmit;
      data.product_id = this.product_id;
      return apiBus.purchase
        .purchaseSummaryDetail(data)
        .then(data => {
          console.log(data.data.data.purchase_target_list);
          this.loading = false;
          this.tableData.items = this.searchTableData(
            data.data.data.purchase_target_list
          );
          this.tableData.totalItems = data.data.data.total;
          this.tableData.status = data.data.message;
          // this.productInfoData(data.data.data);
          this.product_info_items = this.productInfoData(data.data.data);
          return this.tableData.items;
        })
        .catch(error => {
          console.log(error);
          this.loading = false;
          this.$store.dispatch(PROMPT_STATUS, {
            dismissCountDown: 9999999999, //初始提示作用于定时器
            alert_variant: 'danger', // 提示状态 success=>成功 danger=> 报错
            alertText:
              error.data == undefined ? error.message : error.data.message // 提示内容
          });
          this.tableData.totalItems = 0;
          return [];
        });
    },
    init() {
      if (this.options.sales_channel.length == 0) {
        if (Object.keys(this.baseConfig).length == 0) {
          apiBus.purchase
            .getBaseConfig()
            .then(data => {
              console.log('getBaseConfig数据缓存vuex');
              this.options.sales_channel = data.data.data.sales_channel;
              this.$store.dispatch(RENEW_BASE_CONFIG, data.data.data);
              // 给Order Type赋值
            })
            .catch(error => {
              console.log(error);
            });
        } else {
          console.log('有数据');
          this.options.sales_channel = this.baseConfig.sales_channel;
        }
      }

      apiBus.purchase
        .getSupplierList()
        .then(data => {
          this.options.supplier_list =
            data.data.data.supplier_list['Other Supplier'];
        })
        .catch(error => {
          console.log(error);
          this.options.supplier_list = [];
        });
      // this.tableSubmit.product_id = purchaseSummaryId;
      // this.tableSubmit = this.initialTableSubmit;
    },
    // testDataNew() {
    //   return JSON.parse(JSON.stringify(this.tableSubmit));
    // },
    throttling(n) {
      clearTimeout(this.tiemID);
      this.tiemID = setTimeout(() => {
        this.$root.$emit('bv::refresh::table', this.tableData.tabId);

        console.log(n);
      }, 600);
    }
  },

  computed: {
    ...mapState({
      baseConfig: state => state.baseconfig.baseconfig
    }),
    tableDataNew() {
      return JSON.parse(JSON.stringify(this.tableSubmit));
    }
  },

  mounted() {
    this.init();
    let {
      purchaseSummaryId,
      orderDateFrom,
      orderDateTo,
      salesChannel
    } = this.$route.params;
    console.log(this.$route.params);
    this.product_id = purchaseSummaryId;
    this.tableSubmit.order_date_from = orderDateFrom;
    this.tableSubmit.order_date_to = orderDateTo;
    this.tableSubmit.sales_channel = salesChannel;
    this.list_filter += 1;
  },
  watch: {
    tableDataNew: {
      handler: function(n, o) {
        console.log();

        if (o.limit == n.limit && o.page == n.page && this.list_filter != 1) {
          this.throttling(n);
        } else {
          this.list_filter += 1;
        }
      },
      deep: true
    },
    'options.sales_channel'(o) {
      this.searchConfiguration.sales_channel.options = o;
      this.options.sales_channel = o;
    }
  }
};
</script>
<style scoped>
.btn-success {
  color: #fff;
}
</style>
